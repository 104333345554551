import React, { useContext } from 'react';
import { AppContext } from "../../context/AppContext";

const CartIcon = () => {

	const { cart, showViewCart, setShowViewCart } = useContext(AppContext);
	const productsCount = cart.contents?.itemCount;


	return (
		<div className="cart-icon " onClick={() => setShowViewCart(!showViewCart)}>

			<svg id="cart-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="78.298" height="75.895" viewBox="0 0 78.298 75.895">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rechteck_14" data-name="Rechteck 14" width="65.887" height="62.682" transform="translate(0 14.82) rotate(-12.999)" fill="none" />
					</clipPath>
				</defs>
				<g id="Gruppe_98" data-name="Gruppe 98" transform="translate(0 0)" clipPath="url(#clip-path)">
					<path id="Pfad_28" data-name="Pfad 28" d="M65.756,6.81a1.177,1.177,0,0,0-1.02-.168c-12.821,3.766-25.844,6.7-38.437,9.546-4.5,1.007-9.173,2.059-13.749,3.132a1.048,1.048,0,0,0-.174.059L9.138,11.643a1.178,1.178,0,0,0-1.356-.7,9.457,9.457,0,0,0-3.85,2.3A6.851,6.851,0,0,1,1.5,14.824a1.181,1.181,0,1,0,.531,2.3A8.51,8.51,0,0,0,5.454,15.05,12.171,12.171,0,0,1,7.4,13.608L27.078,60.65a1.178,1.178,0,0,0,1.356.7l.068-.016C40.231,57.871,52.28,55.4,63.935,53l2.107-.432a1.182,1.182,0,1,0-.476-2.316l-2.109.433c-11.366,2.336-23.106,4.749-34.607,8.08l-4.5-10.782,40.478-9.346a1.176,1.176,0,0,0,.914-1.086A292.667,292.667,0,0,0,66.251,7.72a1.178,1.178,0,0,0-.493-.908" transform="translate(0.18 2.036)" fill="#fff200" />
					<path id="Pfad_29" data-name="Pfad 29" d="M29.621,47.668a3.939,3.939,0,0,0,1.772,7.677,3.957,3.957,0,1,0-1.772-7.677" transform="translate(8.208 14.664)" fill="#fff200" />
					<path id="Pfad_30" data-name="Pfad 30" d="M45.66,43.921a3.939,3.939,0,1,0,4.724,2.952,3.961,3.961,0,0,0-4.724-2.952" transform="translate(13.28 13.537)" fill="#fff200" />
				</g>
			</svg>

			<div className="itemcount"><h6>{productsCount}</h6></div>
		</div>
	)
};

export default CartIcon;
