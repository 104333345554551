import React from 'react'
import { useSpring, animated } from 'react-spring'
import './burgershop.css'
import './shop.css'


const BurgerShop = (props) => {
    const ani1 = useSpring({ opacity: props.active ? '0' : '1' })
    const ani2 = useSpring({ opacity: props.active ? '1' : '0' })


    return (
        <>
            <animated.svg style={ani1} xmlns="http://www.w3.org/2000/svg" className="fixed-toggler" width="62.012" height="43.238" viewBox="0 0 62.012 43.238">
                <g id="Gruppe_91" data-name="Gruppe 91" transform="translate(-908 -1559.762)">
                    <g id="Gruppe_8" data-name="Gruppe 8" transform="translate(908 1559.762)">
                        <path id="Pfad_11" data-name="Pfad 11" d="M970.013,1572.381c-20.671-16-41.342,16-62.012,0v-8c20.671,16,41.342-16,62.012,0Z" transform="translate(-908 -1559.762)" fill="#fff201" />
                    </g>
                    <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(908 1572.762)">
                        <path id="Pfad_12" data-name="Pfad 12" d="M970.013,1598.381c-20.671-16-41.342,16-62.012,0v-8c20.671,16,41.342-16,62.012,0Z" transform="translate(-908 -1585.762)" fill="#fff201" />
                    </g>
                    <g id="Gruppe_10" data-name="Gruppe 10" transform="translate(908 1585.762)">
                        <path id="Pfad_13" data-name="Pfad 13" d="M970.013,1624.381c-20.671-16-41.342,16-62.012,0v-8c20.671,16,41.342-16,62.012,0Z" transform="translate(-908 -1611.762)" fill="#fff201" />
                    </g>
                </g>
            </animated.svg>
            <animated.svg style={ani2} xmlns="http://www.w3.org/2000/svg" className="fixed-toggler" width="62.226" height="62.225" viewBox="0 0 62.226 62.225">
                <g id="Gruppe_70" data-name="Gruppe 70" transform="translate(-1236.73 1165.312) rotate(-45)">
                    <rect id="Rechteck_5" data-name="Rechteck 5" width="9" height="79" transform="translate(1694 55)" />
                    <rect id="Rechteck_6" data-name="Rechteck 6" width="9" height="79" transform="translate(1738 90) rotate(90)" />
                </g>
            </animated.svg>
        </>
    );
}

export default BurgerShop;