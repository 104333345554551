import { useEffect } from 'react';
import { useLocation } from '@reach/router'; // Gatsby uses @reach/router

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-64DENBSS0M', {
        'page_path': location.pathname,
      });
    }
  }, [location]);
}

export default usePageViews;
