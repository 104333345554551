import React, { useState, useContext, useEffect } from 'react'
import { v4 } from "uuid";
import { Link } from 'gatsby'
import { useQuery, useMutation } from "@apollo/client";
import GET_CART from "../../queries/get-cart";
import CLEAR_CART_MUTATION from "../../mutations/clear-cart";
import REMOVE_ITEMS_FROM_CART from "../../mutations/remove-items";
import { AppContext } from "../context/AppContext";
import './cart.css'
import APPLY_COUPON from '../../mutations/apply-coupon';
import REMOVE_COUPON from '../../mutations/remove-coupon';

export default function Cart({ handleCloseCart }) {


    /* eslint-disable */
    const [requestError, setRequestError] = useState(null);
    const [discountError, setDiscountError] = useState(null);
    const [removeKey, setRemoveKey] = useState(null)
    const [loading, setLoading] = useState(null)
    const [deleteCode, setDeleteCode] = useState(null)
    const [discountValue, setDiscountValue] = useState('');
    const { cart, setCart, showViewCart, setShowViewCart } = useContext(AppContext);
    // Get Cart Data.
    const { data, refetch } = useQuery(GET_CART, {

        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            // console.warn( 'completed GET_CART' );
            // Update cart in the localStorage.
            localStorage.setItem("woo-next-cart", JSON.stringify(data.cart));

            // Update cart data in React Context.
            setCart(data.cart)
            setRemoveKey(null)
        }
    });


    const [clearCart] = useMutation(
        CLEAR_CART_MUTATION, {
        variables: {
            input: {
                clientMutationId: v4(), // Generate a unique id.
                all: true
            },
        },
        onCompleted: () => {
            refetch().then(response => {
                setLoading(false)
                setCart(null)
                setShowViewCart(false)
            });
        },
        onError: (error) => {
            if (error) {
                setCart(null)
                setLoading(false)
                setRequestError("Artikel konnte nicht entfernt werden.");
            }
        },
    }
    );

    const [removeItemsFromCart] = useMutation(
        REMOVE_ITEMS_FROM_CART, {
        variables: {
            input: {
                clientMutationId: v4(), // Generate a unique id.
                keys: removeKey
            },
        },
        onCompleted: () => {
            refetch().then(response => {
                setLoading(false)
            });

        },
        onError: (error) => {
            if (error) {
                setLoading(false)
                setRequestError("Artikel konnte nicht entfernt werden.");
            }
        },
    }
    );

    const [applyCoupon] = useMutation(
        APPLY_COUPON, {
        variables: {
            input: {
                clientMutationId: v4(), // Generate a unique id.
                code: discountValue.toLowerCase()
            },
        },
        onCompleted: () => {
            refetch().then(response => {
                setDiscountError(null)
                setLoading(false)
            });

        },
        onError: (error) => {
            if (error) {
                setLoading(false)
                setDiscountError(error.message)
            }
        },
    }
    );

    const [removeCoupon] = useMutation(
        REMOVE_COUPON, {
        variables: {
            input: {
                clientMutationId: v4(), // Generate a unique id.
                codes: deleteCode
            },
        },
        onCompleted: () => {
            refetch().then(response => {
                setLoading(false)
                setDiscountError(null)
                setDeleteCode(null)
            });

        },
        onError: (error) => {
            if (error) {
                setLoading(false)
                setDiscountError(error.message)
                setDeleteCode(null)
            }
        },
    }
    );


    const handleDiscountChange = (event) => {
        setDiscountValue(event.target.value);
    };




    useEffect(() => {
        setRequestError(null);
        if (removeKey) {
            removeItemsFromCart();
        }
        refetch()

        if (deleteCode != null) {
            removeCoupon()
        }

    }, [deleteCode, removeKey, cart]);


    const handleRemoveFromCart = (key) => {
        setRequestError(null);
        setLoading(true)
        setRemoveKey(key)
    };

    const removeDiscount = async (code) => {
        setDeleteCode(code.toLowerCase())
    };

    const handleClearCart = async (e) => {
        e.preventDefault()
        setLoading(true)
        setRequestError(null)
        clearCart()
    };

    const handleDiscountSubmit = async (e) => {
        e.preventDefault()
        setDiscountValue("");
        applyCoupon()
    };

    return (

        <div className="cart-bg">
            <div className="cart-drawer">
                <div onClick={handleCloseCart} className="close-cart">
                    <h3>X</h3>
                </div>
                <div className="cart-top">
                    <h2>Cart</h2>

                    {loading ? <p>Loading...</p> :

                        cart?.contents?.nodes.map((item, i) => {
                            return (
                                <div key={i} className="cart-item-container">

                                    <div className="cart-product"><h4>{item?.quantity} X </h4><h3>{item?.variation?.node?.name}</h3> </div>
                                    <h6 onClick={() => handleRemoveFromCart(item?.key)} className="cart-qty minus">Remove</h6>

                                </div>
                            )
                        })

                    }
                    <span className="error">{requestError}</span>
                </div>
                <div className="cart-bottom">
                    <form className="discount-form" onSubmit={handleDiscountSubmit}>
                        <input type="text" value={discountValue} onChange={handleDiscountChange} name="discount" className="discount-input" placeholder="Discount" />
                        <input type="submit" onClick={() => handleDiscountSubmit} className="discount-apply" value="Apply" />
                    </form>
                    <span className="error">{discountError}</span>
                    <h3>Discounts</h3>
                    <div className="discount-code-container">
                        {cart?.appliedCoupons?.map((item, i) => {
                            return <div key={item.code} className="discount-codes">{item.code} <div onClick={() => removeDiscount(item.code)} className="remove-discount">x</div></div>
                        })}
                    </div>
                    <h6>Subtotal</h6>
                    <h4>{cart?.contentsTotal}</h4>
                    <h6>Shipping</h6>
                    <h4>{cart?.shippingTotal}</h4>
                    <h6>Total</h6>
                    <h4>{cart?.total}</h4>
                    <Link onClick={() => handleCloseCart()} to="/checkout" className="clear-cart-btn">
                        <h4>Checkout</h4>
                    </Link>
                </div>
            </div>

        </div>
    )

}
