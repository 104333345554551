import React, { useState, useEffect } from "react";

export const AppContext = React.createContext([{}, () => { }]);

export const AppProvider = (props) => {


    const [cart, setCart] = useState(null);

    const [showViewCart, setShowViewCart] = useState(false);

    const [printOrder, setPrintOrder] = useState(null);

    const [sending, setSending] = useState(false)

    useEffect(() => {
        const isSSR = typeof window === "undefined"
        if (!isSSR) {
            let cartData = localStorage.getItem("woo-next-cart");
            setCart(cartData);
        }

    }, []);

    return (
        <AppContext.Provider value={{ cart, setCart, showViewCart, setShowViewCart, sending, setSending, printOrder, setPrintOrder }}>
            {props.children}
        </AppContext.Provider>
    );
};