import React from 'react'
import { useSpring, animated } from 'react-spring'
import './burger.css'


const Burger = (props) => {
    const ani = useSpring({ transform: props.active ? 'rotate(90deg)' : 'rotate(0deg)', top: props.active ? '50px' : '40px', right: props.active ? '20px' : '40px' })

    return (
        <div className="fixed-toggler">
            <animated.button style={ani} className="toggler-button" id="primary-nav" aria-controls="basic-navbar-nav">
                <svg className="dark-svg" viewBox="0 0 612 344" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1">
                        <g fillRule="nonzero" fill="#000000">
                            <path d="M474.934902,37.092 C478.273725,37.092 481,35.7820333 481,34.3120953 L481,19.8719047 C481,18.3419326 478.425098,17.092 474.934902,17.092 L7.06509804,17.092 C3.72705882,17.092 1,18.4019667 1,19.8719047 L1,34.3120953 C1,35.8420674 3.57490196,37.092 7.06509804,37.092 L474.934902,37.092 L474.934902,37.092 Z" id="Shape-1" transform="translate(241.000000, 27.092000) rotate(-4.000000) translate(-241.000000, -27.092000) " id="Shape"></path>
                            <path d="M1,173.402095 C1,174.932067 4.283,176.182 8.733,176.182 L605.266,176.182 C609.522,176.182 612.999,174.871674 612.999,173.402095 L612.999,158.961905 C612.999,157.421867 609.716,156.182 605.266,156.182 L8.733,156.182 C4.477,156.182 1,157.492326 1,158.961905 L1,173.402095 Z" id="Shape-2" transform="translate(306.999500, 166.182000) rotate(-2.000000) translate(-306.999500, -166.182000) " id="Shape"></path>
                            <path d="M82,270.494045 C82,272.024045 84.4139745,273.274 87.6860387,273.274 L526.313961,273.274 C529.443378,273.274 532,271.96365 532,270.494045 L532,256.053595 C532,254.523596 529.586025,253.274 526.313961,253.274 L87.6860387,253.274 C84.5566218,253.274 82,254.58399 82,256.053595 L82,270.494045 Z" id="Shape"></path>
                            <path d="M82,413.494045 C82,415.024045 84.4139745,416.274 87.6860387,416.274 C185.28785,404.274 258.489209,398.274 307.290115,398.274 C355.962081,398.274 428.97003,404.274 526.313961,416.274 C529.443378,416.274 532,414.96365 532,413.494045 L532,399.053595 C532,397.523596 529.586025,396.274 526.313961,396.274 C453.439262,385.147838 380.431313,379.584757 307.290115,379.584757 C234.148917,379.584757 160.947558,385.147838 87.6860387,396.274 C84.5566218,396.274 82,397.58399 82,399.053595 L82,413.494045 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>
                <svg
                    className="light-svg" viewBox="0 0 612 500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1">
                        <g fillRule="nonzero" fill="#FFFFFF">
                            <path d="M474.934902,37.092 C478.273725,37.092 481,35.7820333 481,34.3120953 L481,19.8719047 C481,18.3419326 478.425098,17.092 474.934902,17.092 L7.06509804,17.092 C3.72705882,17.092 1,18.4019667 1,19.8719047 L1,34.3120953 C1,35.8420674 3.57490196,37.092 7.06509804,37.092 L474.934902,37.092 L474.934902,37.092 Z" id="Shape-1" transform="translate(241.000000, 27.092000) rotate(-4.000000) translate(-241.000000, -27.092000)" id="Shape"></path>
                            <path d="M1,173.402095 C1,174.932067 4.283,176.182 8.733,176.182 L605.266,176.182 C609.522,176.182 612.999,174.871674 612.999,173.402095 L612.999,158.961905 C612.999,157.421867 609.716,156.182 605.266,156.182 L8.733,156.182 C4.477,156.182 1,157.492326 1,158.961905 L1,173.402095 Z" id="Shape-2" transform="translate(306.999500, 166.182000) rotate(-2.000000) translate(-306.999500, -166.182000) " id="Shape"></path>
                            <path d="M82,270.494045 C82,272.024045 84.4139745,273.274 87.6860387,273.274 L526.313961,273.274 C529.443378,273.274 532,271.96365 532,270.494045 L532,256.053595 C532,254.523596 529.586025,253.274 526.313961,253.274 L87.6860387,253.274 C84.5566218,253.274 82,254.58399 82,256.053595 L82,270.494045 Z" id="Shape"></path>
                            <path d="M82,413.494045 C82,415.024045 84.4139745,416.274 87.6860387,416.274 C185.28785,404.274 258.489209,398.274 307.290115,398.274 C355.962081,398.274 428.97003,404.274 526.313961,416.274 C529.443378,416.274 532,414.96365 532,413.494045 L532,399.053595 C532,397.523596 529.586025,396.274 526.313961,396.274 C453.439262,385.147838 380.431313,379.584757 307.290115,379.584757 C234.148917,379.584757 160.947558,385.147838 87.6860387,396.274 C84.5566218,396.274 82,397.58399 82,399.053595 L82,413.494045 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>


            </animated.button>
            <button className={`toggler-button-2 ${props.active ? "toggler-active" : ""}`} >
                <svg className="light-svg" viewBox="0 0 612 500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1">
                        <g fillRule="nonzero" fill="#FFFFFF">
                            <path d="M1,173.402095 C1,174.932067 4.283,176.182 8.733,176.182 L605.266,176.182 C609.522,176.182 612.999,174.871674 612.999,173.402095 L612.999,158.961905 C612.999,157.421867 609.716,156.182 605.266,156.182 L8.733,156.182 C4.477,156.182 1,157.492326 1,158.961905 L1,173.402095 Z" id="Shape-2" transform="translate(306.999500, 166.182000) rotate(-2.000000) translate(-306.999500, -166.182000) "></path>
                        </g>
                    </g>
                </svg>
                <svg className="dark-svg" viewBox="0 0 612 344" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1">
                        <g fillRule="nonzero" fill="#000000">
                            <path d="M1,173.402095 C1,174.932067 4.283,176.182 8.733,176.182 L605.266,176.182 C609.522,176.182 612.999,174.871674 612.999,173.402095 L612.999,158.961905 C612.999,157.421867 609.716,156.182 605.266,156.182 L8.733,156.182 C4.477,156.182 1,157.492326 1,158.961905 L1,173.402095 Z" id="Shape-2" transform="translate(306.999500, 166.182000) rotate(-2.000000) translate(-306.999500, -166.182000) "></path>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    );
}

export default Burger;