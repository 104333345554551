import React from 'react';

import { ParallaxProvider } from "react-scroll-parallax";
import { ApolloProvider } from '@apollo/client/react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { AppProvider } from "./src/components/context/AppContext";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Layout from './src/components/layout';
import 'lazysizes'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY);

const customFetch = (uri, options) => {
    const { operationName } = JSON.parse(options.body);
    if (operationName === "Vimeo") {
        return fetch(process.env.GATSBY_GQL_LOCAL_URL_PROTOCOL, options);
    }
    return fetch(process.env.GATSBY_GQL_URL_PROTOCOL, options);
};

const link = new HttpLink({ fetch: customFetch });

export const middleware = new ApolloLink((operation, forward) => {
    const session = localStorage.getItem('woo-session');
    if (session) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                'woocommerce-session': `Session ${session}`,
            }
        }));
    }
    return forward(operation);
});

export const afterware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext();
        const { response: { headers } } = context;
        const session = headers.get('woocommerce-session');

        if (session) {
            if (localStorage.getItem('woo-session') !== session) {
                localStorage.setItem('woo-session', headers.get('woocommerce-session'));
            }
        }

        return response;
    });
});

const client = new ApolloClient({
    link: ApolloLink.from([
        middleware,
        afterware,
        link
    ]),
    cache: new InMemoryCache(),
    clientState: {},
});

export const wrapPageElement = ({ element, props }) => {
    return (
        <ApolloProvider client={client}><Elements stripe={stripePromise}><AppProvider><ParallaxProvider><Layout {...props}>{element}</Layout></ParallaxProvider></AppProvider></Elements></ApolloProvider>
    );
}


export const onInitialClientRender = () => {
    !(function (s, a, l, e, sv, i, ew, er) {
        try {
            (a = s[a] || s[l] || function () { throw "no_xhr"; }), (sv = i = "https://salesviewer.org"), (ew = function (x) { (s = new Image()), (s.src = "https://salesviewer.org/tle.gif?sva=S5s8z0a4w2s8&u=" + encodeURIComponent(window.location) + "&e=" + encodeURIComponent(x)) }), (l = s.SV_XHR = function (d) {
                return ((er = new a()), (er.onerror = function () {
                    if (sv != i) return ew("load_err");
                    (sv = "https://www.salesviewer.com/t"), setTimeout(l.bind(null, d), 0);
                }), (er.onload = function () { (s.execScript || s.eval).call(er, er.responseText); }), er.open("POST", sv, !0), (er.withCredentials = true), er.send(d), er);
            }), l("h_json=" + 1 * ("JSON" in s && void 0 !== JSON.parse) + "&h_wc=1&h_event=" + 1 * ("addEventListener" in s) + "&sva=" + e);
        } catch (x) { ew(x) }
    })(window, "XDomainRequest", "XMLHttpRequest", "S5s8z0a4w2s8");

    if (window.gtag) {
        window.gtag("js", new Date());
        window.gtag("config", "G-64DENBSS0M");
    }
};