import { gql } from "@apollo/client";

const APPLY_COUPON = gql`
mutation ($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
        cart {
            availableShippingMethods {
              rates {
                id
                label
                methodId
                cost
                instanceId
              }
            }
              contents {
                productCount
                itemCount
                nodes {
                  quantity
                  key
                  total
                  product {
                    node {
                      id
                      image {
                        altText
                        srcSet
                        sourceUrl
                      }
                    }
                  }
                  variation {
                      attributes {
                        attributeId
                        id
                        label
                        name
                        value
                      }
                      node {
                          id
                          name
                          slug
                          regularPrice
                          salePrice
                          databaseId
                          sku
                        }
                    }
                }
              }
              totalTax
              total
              subtotalTax
              subtotal
              shippingTotal
              shippingTax
              needsShippingAddress
              isEmpty
              feeTotal
              feeTax
              displayPricesIncludeTax
              discountTax
              discountTotal
              contentsTotal
              contentsTax
            }
    }
}
`;

export default APPLY_COUPON;
