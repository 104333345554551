/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css'
import CookieConsent from "react-cookie-consent";
import { AppContext } from "./context/AppContext";

import Header from "./header"
import Cart from "./cart/cart"
import CartIcon from "./cart/cart-icon"
import { useQuery } from "@apollo/client";
import GET_CART from "../queries/get-cart";
import { createGlobalStyle } from 'styled-components'
import NeueMontrealEOT from './fonts/NeueMontreal-Regular.eot'
import NeueMontrealWOFF from './fonts/NeueMontreal-Regular.woff'
import NeueMontrealWOFF2 from './fonts/NeueMontreal-Regular.woff2'
import Cactus from './fonts/CactusJack.woff'
import CactusAlter from './fonts/CactusJackAlternate.woff'
import NeueMontrealBoldEOT from './fonts/NeueMontreal-Bold.eot'
import NeueMontrealBoldWOFF from './fonts/NeueMontreal-Bold.woff'
import NeueMontrealBoldWOFF2 from './fonts/NeueMontreal-Bold.woff2'
import TFCIT from './fonts/TFCITD-Bold.otf'
import './layout.css'
import usePageViews from '../hooks/usePageView';




const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Cactus';
    src: url(${Cactus}) format('woff');
  }
  @font-face {
    font-family: 'CactusAlternate';
    src: url(${CactusAlter});
  }
  @font-face {
    font-family: 'NeueMontreal';
    src: url(${NeueMontrealEOT}); /* IE9 Compat Modes */
    src: url('${NeueMontrealEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${NeueMontrealWOFF2}) format('woff2'), /* Super Modern Browsers */
        url(${NeueMontrealWOFF}) format('woff'); /* Pretty Modern Browsers */
    font-weight: normal;
  }
  @font-face {
    font-family: 'TFCIT';
    src: url(${TFCIT}) format('opentype');
  }

  @font-face {
    font-family: 'NeueMontreal';
    src: url(${NeueMontrealBoldEOT}); /* IE9 Compat Modes */
    src: url('${NeueMontrealBoldEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${NeueMontrealBoldWOFF2}) format('woff2'), /* Super Modern Browsers */
        url(${NeueMontrealBoldWOFF}) format('woff'); /* Pretty Modern Browsers */
    font-weight: bold;
  }

  

`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  let lang = children?.props?.pageContext?.lang
  let pathAdded = '/'

  usePageViews();


  function ContainsAny(str, items) {
    for (var i in items) {
      var item = items[i];
      if (str.indexOf(item) > -1) {
        return true;
      }

    }
    return false;
  }

  function isShop(location) {
    if (location != undefined) {
      if (ContainsAny(location, ["/shop", "/checkout", "/cart", "/product/"])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  const { cart, setCart, showViewCart, setShowViewCart } = useContext(AppContext);
  const [fontsLoaded, setFontsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFontsLoaded(true);
    }, 800)
  }, [])

  // Get Cart Data.
  const { val, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (val) => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      localStorage.setItem("woo-next-cart", JSON.stringify(val.cart));

      // Update cart data in React Context.
      setCart(val.cart);

    }
  });

  const handleCloseCart = (e) => {
    setShowViewCart(false);
    refetch();
  }


  const isSSR = typeof window === "undefined"
  

  let path = ''
  let languageName = ''

  if (lang == 'de') {
    pathAdded = children?.props?.location?.pathname

    if (pathAdded == '/category/blog/') {
      path = '/en/category/blog-en/'
    } else {
      path = '/en' + pathAdded
    }

    languageName = 'EN'
  } else {
    let germanPath = ''
    pathAdded = children?.props?.location?.pathname

    if (pathAdded && pathAdded != '/404/') {
      if (pathAdded == '/en/category/blog-en/') {
        germanPath = '/category/blog/';
      } else {
        germanPath = children?.props?.location?.protocol + '//' + children?.props?.location?.host + pathAdded.replace('/en/', '/')
      }

    } else {
      germanPath = '/404/'
    }

    path = germanPath
    languageName = 'DE'
  }


  return (
    <div className={`wrapper ${isShop(location?.pathname) ? 'shop-wrapper' : ''}`}>
      {children?.props?.pageContext ?
        <>
          {children.props.pageContext.lang == 'de' ?
            <>
              <Header siteTitle={data.site.siteMetadata.title} lang="de" location={location?.pathname} />
              <CookieConsent
                location="bottom"
                buttonText="Das finde ich ok"
                enableDeclineButton
                declineButtonText="Nicht ok"
                cookieName="_ga"
                flipButtons
                style={{ background: "#000", fontSize: "2vw", flex: "auto", textAlign: "right", fontFamily: "Cactus" }}
                declineButtonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
                buttonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
                expires={150}

              >
                Dieser Text weist darauf hin, dass diese Seite Cookies verwendet.
              </CookieConsent>
            </>
            :
            <>
              <Header location={location?.pathname} siteTitle={data.site.siteMetadata.title} lang="en" />
              <CookieConsent
                location="bottom"
                buttonText="That's ok"
                enableDeclineButton
                declineButtonText="Not ok"
                cookieName="_ga"
                flipButtons
                style={{ background: "#000", fontSize: "2vw", flex: "auto", textAlign: "right", fontFamily: "Cactus" }}
                declineButtonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
                buttonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
                expires={150}

              >
                This text indicates, that this site uses cookies.
              </CookieConsent>
            </>
          }
        </>
        :
        <>
          <Header siteTitle={data.site.siteMetadata.title} lang="de" />
          <CookieConsent
            location="bottom"
            buttonText="Das finde ich ok"
            enableDeclineButton
            declineButtonText="Nicht ok"
            cookieName="_ga"
            flipButtons
            style={{ background: "#000", fontSize: "1vw", flex: "auto", textAlign: "left", fontFamily: "Futura" }}
            declineButtonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
            buttonStyle={{ color: "#fff", backgroundColor: "transparent", fontFamily: "Cactus", fontSize: "2vw" }}
            expires={150}

          >
            Wir verwenden Cookies, um Inhalte anzuzeigen, zu personalisieren und die Zugriffe auf unsere Website zu analysieren.
          </CookieConsent>
        </>

      }

      <div>
        <GlobalStyles />
        <main>{children}</main>
        {cart && cart.contents?.itemCount > 0 ?
          <CartIcon ></CartIcon>
          :
          ''
        }
        {showViewCart &&
          <Cart handleCloseCart={handleCloseCart} cart={cart} >
          </Cart>

        }

      </div>
      {fontsLoaded ?
        <div className="lang_menu">
          <a href={path}>{languageName}</a>
        </div>
        : <></>

      }
    </div>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
