import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Burger from '../burger/burger'
import BurgerShop from '../burgershop/burgershop'
import './nav.css'
import Logo from '../Logo/logo'
import AniLink from "gatsby-plugin-transition-link/AniLink";




export default function MainMenu({ lang, location }) {

  const [active, setActive] = useState(false);
  const [collapse, setCollapse] = useState('');
  const [mixBlend, setMixBlend] = useState('difference');




  function ContainsAny(str, items) {
    for (var i in items) {
      var item = items[i];
      if (str.indexOf(item) > -1) {
        return true;
      }

    }
    return false;
  }

  function isShop(location) {
    if (location != undefined) {
      if (ContainsAny(location, ["/shop", "/checkout", "/cart", "/product/"])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }




  const handleClick = (e) => {
    setActive(active => !active);
    if (!active) {

      if (isShop(location)) {
        setMixBlend('normal');
      } else {
        setCollapse('open');
        setMixBlend('dark');
      }
    } else {

      if (isShop(location)) {
        setMixBlend('normal');
      } else {
        setCollapse('');
        setMixBlend('difference');
      }
    }
  };
  const ani = useSpring({ height: active ? '100%' : '0%' })

  const props = useStaticQuery(graphql`
  {
    wpcontent{
      menusde: menus(where: {language: "de"}) {
        nodes {
          id
          name
          menuItems {
            edges {
              node{
                label
                path
                id
              }
            }
          }
        }
      }
      menusen: menus(where: {language: "en"}) {
        nodes {
          id
          name
          menuItems {
            edges {
              node{
                label
                path
                id
              }
            }
          }
        }
      }
      page(id: "407", idType: DATABASE_ID) {
        title
        content
      }
      themeFooterSettings {
        footerSettings {
          menuText
          impressum
          blackLogo{
            altText
            sourceUrl
            srcSet
          }
        }
      }
      menusshopde: menus(where: {language: "de", location: SHOP_FOOTER_MENU}) {
        nodes {
          id
          name
          menuItems {
            edges {
              node {
                label
                path
                id
              }
            }
          }
        }
      }
      menusshopen: menus(where: {language: "en", location: SHOP_FOOTER_MENU}) {
        nodes {
          id
          name
          menuItems {
            edges {
              node {
                label
                path
                id
              }
            }
          }
        }
      }
    }
  }
  `);


  let path = ''
  let languageName = ''

  if (lang == 'de') {

    path = '/en/'
    languageName = 'EN'
  } else {

    path = '/'
    languageName = 'DE'
  }




  return (
    <div>
      <Navbar className={`navbar-obscura ${isShop(location) ? '' : mixBlend}`} expand="md">
        {lang == 'de' ?
          <a href="/">
            <Navbar.Brand>
              <Logo />
            </Navbar.Brand>
          </a>
          :
          <a href="/en/">
            <Navbar.Brand>
              <Logo />
            </Navbar.Brand>
          </a>
        }
        <div onClick={handleClick}>
          {isShop(location) ?
            <BurgerShop active={active} />
            :
            <Burger active={active} />
          }
        </div>



      </Navbar>

      <animated.div className={`navbar-collapse ${collapse}`} style={ani}>
        <Container fluid className="menu-container">
          {isShop(location) ?
            <div className="shopMenu">
              <Row>
                <Col xs={12} md={7}>
                  <div className='shop-nav-left' dangerouslySetInnerHTML={{ __html: props.wpcontent.themeFooterSettings.footerSettings?.menuText }} />
                </Col>
                <Col xs={12} md={5} className="shop-nav-p">
                  <div className='shop-nav-right-headline' dangerouslySetInnerHTML={{ __html: props.wpcontent.page.title }} />
                  <div className='shop-nav-right' dangerouslySetInnerHTML={{ __html: props.wpcontent.themeFooterSettings.footerSettings?.impressum }} />
                  <img className='shop-nav-right-image' src={props.wpcontent.themeFooterSettings.footerSettings?.blackLogo?.sourceUrl} srcSet={props.wpcontent.themeFooterSettings.footerSettings?.blackLogo?.srcSet} alt={props.wpcontent.themeFooterSettings.footerSettings?.blackLogo?.altText} />
                  <Row className="bottom-row">
                    <Col xs={12} md={12}>
                      <ul>
                        {props.wpcontent.menusshopde.nodes[0].menuItems.edges.map(item => (
                          <li key={item.node.label}>
                            <a href={item.node.path} target="_blank" dangerouslySetInnerHTML={{ __html: item.node?.label }} />
                          </li>
                        ))}

                      </ul>
                    </Col>

                  </Row>
                </Col>
              </Row>
            </div>
            : <>
              {lang == 'de' ?
                <>

                  {props.wpcontent.menusde.nodes[2]?.menuItems?.edges.map(item => (

                    <AniLink fade onClick={handleClick} to={`${item.node.path}`} key={item.node.id} >
                      <Nav.Item>{item.node.label}</Nav.Item>
                    </AniLink>
                  ))}
                </>
                :
                <>

                  {props.wpcontent.menusen.nodes[2]?.menuItems?.edges.map(item => (
                    <AniLink fade onClick={handleClick} to={`${item.node.path}`} key={item.node.id} >
                      <Nav.Item>{item.node.label}</Nav.Item>
                    </AniLink>
                  ))}
                </>

              }

              <a href="https://tfcitd.com" target="_blank">
                <div className="nav-item">TFCITD</div>
              </a>
            </>
          }
        </Container>
      </animated.div>
    </div>
  )

}
