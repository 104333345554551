import PropTypes from "prop-types"
import React from "react"
import MainMenu from './nav/mainMenu'

const Header = ({ siteTitle = ``, lang, location }) => (
  <header>
    <div>
      <MainMenu lang={lang} location={location} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
