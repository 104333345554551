import { gql } from "@apollo/client";

const GET_CART = gql`
    query GET_CART {
        __typename
        cart(recalculateTotals: true) {
          appliedCoupons {
            code
            discountAmount
            discountTax
          }
          availableShippingMethods {
            rates {
              id
              label
              methodId
              cost
              instanceId
            }
          }
            contents {
              productCount
              itemCount
              nodes {
                quantity
                key
                total
                product {
                  node {
                    id
                    image {
                      altText
                      srcSet
                      sourceUrl
                    }
                  }
                }
                variation {
                    attributes {
                      attributeId
                      id
                      label
                      name
                      value
                    }
                    node {
                        id
                        name
                        slug
                        regularPrice
                        salePrice
                        databaseId
                        sku
                      }
                  }
              }
            }
            totalTax
            total
            subtotalTax
            subtotal
            shippingTotal
            shippingTax
            needsShippingAddress
            isEmpty
            feeTotal
            feeTax
            displayPricesIncludeTax
            discountTax
            discountTotal
            contentsTotal
            contentsTax
          }
    }
`;



export default GET_CART;